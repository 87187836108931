// react/gatsby imports
import { useStaticQuery, graphql } from "gatsby";

const useConstructivismQuery = () => {
  const prismicData = useStaticQuery(graphql`
    query constructivismQuery {
      course: prismicCoursetype(
        data: { coursetitle: { text: { eq: "Графсистема Конструктивизм" } } }
      ) {
        uid
        data {
          body {
            ... on PrismicCoursetypeDataBodyTags {
              id
              slice_type
              items {
                coursetag {
                  text
                }
              }
            }
          }
          coursetitle {
            text
          }
          coursedescription {
            text
          }
          coursestartandend {
            text
          }
          coursestatus
          coursestream
          coursetype
          courseprice
          coursemainimage {
            gatsbyImageData
          }
        }
      }
      page: prismicPage(uid: { eq: "constructivism" }) {
        uid
        data {
          seodescription {
            text
          }
          seoimage {
            url(imgixParams: { width: 1200 })
            thumbnails {
              vk {
                url(imgixParams: { width: 510 })
              }
            }
          }
        }
      }
    }
  `);

  return prismicData;
};

export default useConstructivismQuery;
